<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <ListSkeleton v-if="listSkeleton" />
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height="
                cashFlowReportList.data && cashFlowReportList.data.length > 0
                  ? '400'
                  : ''
              "
              :style="{ maxHeight: !cashFlowReportList.data ? '520px' : '' }"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="displayedHeaders"
              :items="
                row === 'detailed' ? invoiceData : cashFlowReportList.data
              "
              :item-class="dynamicColor"
              fixed-header
              :search="search"
              :loading="isProgressCircular"
              @pagination="newPagination"
              @page-count="pageCount = $event"
              :items-per-page="limitData"
              :page.sync="page"
            >
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary"
                    >mdi-timer-sand-paused</v-icon
                  >
                </v-overlay>
              </template>
              <template v-slot:[`item.document_number`]="props">
                <span @dblclick="doubleClick(props)">{{
                  props.item.document_number ? props.item.document_number : " "
                }}</span>
              </template>
              <template v-slot:top>
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">
                    {{ $t("debitReport.filters") }}
                  </h6>
                  <v-spacer />
                  <HtmlToPdf
                    class="me-2 mb-1"
                    :model="cashFlowReportList"
                    :disabled="
                      !!(
                        (cashFlowReportList.data &&
                          cashFlowReportList.data.length) ||
                        (invoiceData && invoiceData.length)
                      )
                    "
                    :row="row"
                    :payload="payload"
                    type="CashFlowReportPDF"
                  />
                  <ExcelAndResetFilterCommon
                    :data="
                      cashFlowReportList.data &&
                      cashFlowReportList.data.length > 0
                    "
                    @downloadExcelFile="downloadExcelFile"
                    @resetFilters="resetFilters"
                  />
                </v-card-title>
                <v-divider />

                <v-form v-model="valid" ref="form">
                  <v-card-text>
                    <v-row :dense="true">
                      <v-col cols="5">
                        <v-row :dense="true">
                          <v-col cols="12" class="d-flex">
                            <v-menu
                              ref="invoiceStartDateMenu"
                              v-model="invoiceStartDateMenu"
                              :close-on-content-click="false"
                              :return-value.sync="invoice_start_date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  hide-details="auto"
                                  dense
                                  class="me-2"
                                  v-model="formattedFromDate"
                                  :label="$t('debitReport.fromInvoiceDate')"
                                  @input="buildUrl"
                                  readonly
                                  :rules="
                                    profileById.date_format
                                      ? dateValid
                                      : dateValid2
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="invoice_start_date"
                                @input="buildUrl"
                                @change="
                                  $refs.invoiceStartDateMenu.save(
                                    invoice_start_date
                                  )
                                "
                              >
                                <v-btn
                                  small
                                  class="primary"
                                  @click="
                                    $refs.invoiceStartDateMenu.save(todayDate())
                                  "
                                  >{{ $t("route.today") }}</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                            <v-menu
                              ref="invoiceEndDateMenu"
                              v-model="invoiceEndDateMenu"
                              :close-on-content-click="false"
                              :return-value.sync="invoice_end_date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  hide-details="auto"
                                  dense
                                  v-model="formattedToDate"
                                  :label="$t('debitReport.toInvoiceDate')"
                                  @input="buildUrl"
                                  readonly
                                  :rules="
                                    profileById.date_format
                                      ? dateValid
                                      : dateValid2
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :min="
                                  invoice_start_date ? invoice_start_date : ''
                                "
                                v-model="invoice_end_date"
                                @input="buildUrl"
                                @change="
                                  $refs.invoiceEndDateMenu.save(
                                    invoice_end_date
                                  )
                                "
                              >
                                <v-btn
                                  small
                                  class="primary"
                                  @click="
                                    $refs.invoiceEndDateMenu.save(todayDate())
                                  "
                                  >{{ $t("route.today") }}</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" class="d-flex">
                            <v-menu
                              ref="paymentStartDateMenu"
                              v-model="paymentStartDateMenu"
                              :close-on-content-click="false"
                              :return-value.sync="payment_start_date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  hide-details="auto"
                                  class="me-2"
                                  v-model="formattedPaymentFromDate"
                                  :label="$t('debitReport.fromPaymentDate')"
                                  @input="buildUrl"
                                  readonly
                                  :rules="
                                    profileById.date_format
                                      ? dateValid
                                      : dateValid2
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="payment_start_date"
                                @input="buildUrl"
                                @change="
                                  $refs.paymentStartDateMenu.save(
                                    payment_start_date
                                  )
                                "
                              >
                                <v-btn
                                  small
                                  class="primary"
                                  @click="
                                    $refs.paymentStartDateMenu.save(todayDate())
                                  "
                                  >{{ $t("route.today") }}</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                            <v-menu
                              ref="paymentEndDateMenu"
                              v-model="paymentEndDateMenu"
                              :close-on-content-click="false"
                              :return-value.sync="payment_end_date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  hide-details="auto"
                                  v-model="formattedPaymentToDate"
                                  :label="$t('debitReport.toPaymentDate')"
                                  @input="buildUrl"
                                  readonly
                                  :rules="
                                    profileById.date_format
                                      ? dateValid
                                      : dateValid2
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :min="
                                  payment_start_date ? payment_start_date : ''
                                "
                                v-model="payment_end_date"
                                @input="buildUrl"
                                @change="
                                  $refs.paymentEndDateMenu.save(
                                    payment_end_date
                                  )
                                "
                              >
                                <v-btn
                                  small
                                  class="primary"
                                  @click="
                                    $refs.paymentEndDateMenu.save(todayDate())
                                  "
                                  >{{ $t("route.today") }}</v-btn
                                >
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1"> </v-col>
                      <v-col cols="6">
                        <v-row :dense="true">
                          <v-col cols="6">
                            <v-autocomplete
                              autocomplete="new-password"
                              dense
                              cache-items
                              v-model="selectedCustomer"
                              :items="customerList.data"
                              item-text="name_1"
                              item-value="id"
                              flat
                              return-object
                              hide-no-data
                              hide-details="auto"
                              single-line
                              clearable
                              @change="onCustomerChange"
                              @click:clear="onCustomerChange"
                              :search-input.sync="customerSearch"
                              :label="$t('debitReport.customer')"
                            >
                              <template v-slot:append-outer>
                                <v-icon
                                  color="primary"
                                  dense
                                  @click="customerDialog = true"
                                >
                                  mdi-magnify
                                </v-icon>
                              </template>
                              <template v-slot:selection="data">
                                <span>{{
                                  `${data.item.id}. ${data.item.name_1}`
                                }}</span>
                              </template>
                              <template v-slot:item="data">
                                <div
                                  class="font-weight-medium"
                                  style="
                                    font-size: 0.8125rem;
                                    display: flex;
                                    align-item: center;
                                  "
                                >
                                  {{ `${data.item.id}. ${data.item.name_1}` }}
                                </div>
                              </template>
                              <template v-slot:append-item>
                                <div
                                  v-show="hasNextPage"
                                  v-intersect="infiniteScroll"
                                  ref="load"
                                  class="loader text-center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="primary"
                                  />
                                </div>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete
                              hide-details="auto"
                              dense
                              v-model="selectedCustomerGroup"
                              :items="customerGroupValue"
                              item-text="description"
                              item-value="id"
                              clearable
                              flat
                              @input="buildUrl"
                              return-object
                              :label="$t('debitReport.group')"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete
                              autocomplete="new-password"
                              cache-items
                              v-model="selectedSupplier"
                              :items="supplierList.data"
                              item-text="name_1"
                              item-value="id"
                              flat
                              return-object
                              hide-no-data
                              hide-details="auto"
                              single-line
                              clearable
                              @change="onSupplierChange"
                              @click:clear="onSupplierChange"
                              :search-input.sync="supplierSearch"
                              :label="$t('supplier.supplier')"
                            >
                              <template v-slot:append-outer>
                                <v-icon
                                  color="primary"
                                  dense
                                  @click="supplierDialog = true"
                                >
                                  mdi-magnify
                                </v-icon>
                              </template>
                              <template v-slot:selection="data">
                                <span>{{
                                  `${data.item.id}. ${data.item.name_1}`
                                }}</span>
                              </template>
                              <template v-slot:item="data">
                                <div
                                  class="font-weight-medium"
                                  style="
                                    font-size: 0.8125rem;
                                    display: flex;
                                    align-item: center;
                                  "
                                >
                                  {{ `${data.item.id}. ${data.item.name_1}` }}
                                </div>
                              </template>
                              <template v-slot:append-item>
                                <div
                                  v-show="hasSupplierNextPage"
                                  v-intersect="infiniteScrollSupplier"
                                  ref="load"
                                  class="loader text-center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="primary"
                                  />
                                </div>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="6">
                            <v-autocomplete
                              hide-details="auto"
                              v-model="selectedSupplierGroup"
                              :items="supplierGroupValue"
                              item-text="description"
                              item-value="id"
                              clearable
                              flat
                              @input="buildUrl"
                              return-object
                              :label="$t('supplier.field.group')"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row :dense="true">
                      <v-col cols="5">
                        <v-row>
                          <v-radio-group
                            v-model="row"
                            row
                            @change="radioChange"
                          >
                            <v-radio
                              :label="$t('common.details')"
                              value="detailed"
                            ></v-radio>
                            <v-radio
                              :label="$t('common.daily')"
                              value="daily"
                            ></v-radio>
                            <v-radio
                              :label="$t('common.monthly')"
                              value="monthly"
                            ></v-radio>
                            <v-radio
                              :label="$t('debitReport.focused')"
                              value="focused"
                            ></v-radio>
                          </v-radio-group>
                        </v-row>
                      </v-col>
                      <v-col cols="1"></v-col>
                      <v-col cols="6">
                        <v-row :dense="true">
                          <v-col cols="6">
                            <v-select
                              hide-details="auto"
                              class="mt-4"
                              dense
                              clearable
                              v-model="selectedPaymentType"
                              :items="paymentGroupValue"
                              :label="$t('receipt.paymentType')"
                              item-text="description"
                              item-value="id"
                              return-object
                              multiple
                              singleLine
                              :menu-props="{
                                bottom: true,
                                offsetY: true,
                                maxHeight: 200,
                                overflowY: true,
                              }"
                            >
                            </v-select>
                          </v-col>

                          <v-checkbox
                            class="mx-4 mt-4"
                            :dense="true"
                            v-model="withoutCustomer"
                            :disabled="withoutSupplier"
                            :label="$t('cashFlow.withoutCustomer')"
                          />
                          <v-checkbox
                            :dense="true"
                            class="mx-4 mt-4"
                            v-model="withoutSupplier"
                            :disabled="withoutCustomer"
                            :label="$t('cashFlow.withoutSupplier')"
                          />
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" class="text-center mt-3">
                        <ExecuteAndRestCommon
                          :valid="valid"
                          @reset="reset"
                          @paginate="paginate(false)"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="10" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">
                        {{ $t("route.cashFlow") }}
                      </h6>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center">{{
                  $t("common.noDataFound")
                }}</v-card-text>
              </template>

              <!-- daily headers -->
              <template v-slot:[`item.date`]="props">
                <span>{{
                  props.item.date &&
                  (profileById.date_format
                    ? formatDateDDMMYYYY(props.item.date)
                    : props.item.date)
                }}</span>
              </template>
              <template v-slot:[`item.day`]="props">
                <span>{{
                  days.includes(props.item.day)
                    ? $t(`cashFlow.days.${props.item.day}`)
                    : ""
                }}</span>
              </template>
              <template v-slot:[`item.totalcredit`]="props">
                <span
                  v-if="accessRight.includes('financial')"
                  style="direction: ltr"
                  class="d-inline-block"
                >
                  {{
                    props.item.totalcredit
                      ? numberWithCommas(props.item.totalcredit)
                      : ""
                  }}
                </span>
              </template>
              <template v-slot:[`item.totaldebit`]="props">
                <span
                  v-if="accessRight.includes('financial')"
                  style="direction: ltr"
                  class="d-inline-block"
                >
                  {{
                    props.item.totaldebit
                      ? numberWithCommas(props.item.totaldebit)
                      : ""
                  }}
                </span>
              </template>
              <template v-slot:[`item.total`]="props">
                <span
                  v-if="accessRight.includes('financial')"
                  style="direction: ltr"
                  class="d-inline-block"
                >
                  {{
                    props.item.total ? numberWithCommas(props.item.total) : ""
                  }}
                </span>
              </template>
              <template v-slot:[`item.balance`]="props">
                <span
                  v-if="accessRight.includes('financial')"
                  style="direction: ltr"
                  class="d-inline-block"
                >
                  {{
                    props.item.balance
                      ? numberWithCommas(props.item.balance)
                      : ""
                  }}
                </span>
              </template>
              <!-- // daily headers //  -->

              <!-- detailed headers -->
              <template v-slot:[`item.payment_type`]="props">
                <span>{{
                  typeHebrew.includes(props.item.payment_type)
                    ? $t(`cashFlow.${props.item.payment_type}`)
                    : ""
                }}</span>
              </template>
              <template v-slot:[`item.payment_date`]="props">
                <span>
                  {{
                    props.item.payment_date &&
                    (profileById.date_format
                      ? formatDateDDMMYYYY(
                          new Date(props.item.payment_date)
                            .toISOString()
                            .substring(0, 10)
                        )
                      : new Date(props.item.payment_date)
                          .toISOString()
                          .substring(0, 10))
                  }}
                </span>
              </template>
              <template v-slot:[`item.credit`]="props">
                <span
                  v-if="accessRight.includes('financial')"
                  style="direction: ltr"
                  class="d-inline-block"
                  :class="!props.item.payment_type ? 'font-weight-bold' : ''"
                >
                  {{
                    props.item.credit ? numberWithCommas(props.item.credit) : ""
                  }}
                </span>
              </template>
              <template v-slot:[`item.customer_name`]="props">
                <span>
                  {{
                    props.item.customer_name
                      ? props.item.customer_name
                      : props.item.supplier_name
                      ? props.item.supplier_name
                      : ""
                  }}
                </span>
              </template>
              <template v-slot:[`item.debit`]="props">
                <span
                  v-if="accessRight.includes('financial')"
                  style="direction: ltr"
                  class="d-inline-block"
                  :class="!props.item.payment_type ? 'font-weight-bold' : ''"
                >
                  {{
                    props.item.debit ? numberWithCommas(props.item.debit) : ""
                  }}
                </span>
              </template>
              <template v-slot:[`item.cumulativeTotal`]="props">
                <span
                  v-if="accessRight.includes('financial')"
                  style="direction: ltr"
                  class="d-inline-block"
                  :class="
                    !props.item.payment_type &&
                    !props.item.day &&
                    !props.item.month &&
                    !props.item.totalcredit
                      ? 'font-weight-bold'
                      : ''
                  "
                >
                  {{
                    props.item.cumulativeTotal
                      ? numberWithCommas(props.item.cumulativeTotal)
                      : ""
                  }}
                </span>
              </template>
              <!-- // detailed headers // -->

              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        :length="cashFlowReportList.data ? pageCount : 0"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
            <v-divider />
            <v-row>
              <v-col class="mx-auto" cols="2" v-if="row !== 'detailed'">
                <span class="ps-3 pt-2 text-caption"
                  >{{ $t("common.numberOfTotalRows") }}
                  {{
                    cashFlowReportList && cashFlowReportList.data
                      ? cashFlowReportList.data.length
                      : 0
                  }}
                </span>
              </v-col>
              <v-col class="mx-auto" cols="2" v-else>
                <span class="ps-3 pt-2 text-caption"
                  >{{ $t("common.numberOfTotalRows") }}
                  {{ invoiceData ? invoiceData.length : 0 }}
                </span>
              </v-col>
              <v-col cols="4">
                <v-row dense v-if="accessRight.includes('financial')">
                  <v-col cols="6" class="text-end font-weight-bold">
                    <span class="ps-6 pt-2 text-body-2 font-weight-bold">
                      {{ $t("cashFlow.totalcredit") }} :
                      {{
                        cashFlowReportList && cashFlowReportList.sumTotalCredit
                          ? numberWithCommas(cashFlowReportList.sumTotalCredit)
                          : 0
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3">
                <v-row dense v-if="accessRight.includes('financial')">
                  <v-col cols="6" class="text-end font-weight-bold">
                    <span class="ps-6 pt-2 text-body-2 font-weight-bold">
                      {{ $t("cashFlow.totaldebit") }} :
                      {{
                        cashFlowReportList && cashFlowReportList.sumTotalDebit
                          ? numberWithCommas(cashFlowReportList.sumTotalDebit)
                          : 0
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3">
                <v-row dense v-if="accessRight.includes('financial')">
                  <v-col cols="6" class="text-end font-weight-bold">
                    <span class="ps-6 pt-2 text-body-2 font-weight-bold">
                      {{ $t("invoice.total") }} :
                      {{
                        cashFlowReportList && cashFlowReportList.sumBalance
                          ? numberWithCommas(cashFlowReportList.sumBalance)
                          : 0
                      }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <CustomerDialog
        @onCustomerChange="onCustomerChange"
        :dialog.sync="customerDialog"
      />
      <SupplierDialog
        @onSupplierChange="onSupplierChange"
        :dialog.sync="supplierDialog"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName } from "@/utils";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import { formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate } from "@/utils";
import CustomerDialog from "@/components/moduleTableDialog/Dialog/CustomerDialog";
import SupplierDialog from "@/components/moduleTableDialog/Dialog/SupplierDialog";
import exportFromJSON from "export-from-json";
import ExcelAndResetFilterCommon from "@/components/ExcelAndResetFilterCommon";
import ExecuteAndRestCommon from "@/components/ExecuteAndRestCommon";
import { validationMixin } from '@/mixins/validationMixin';
import { intersectionMixin } from '@/mixins/intersectionMixin';
import { searchMixin } from '@/mixins/searchMixin';

export default {
  name: "CashFlow",
  components: {
    ListSkeleton,
    HtmlToPdf,
    CustomerDialog,
    SupplierDialog,
    ExcelAndResetFilterCommon,
    ExecuteAndRestCommon,
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      sortByValue: null,
      sortDescValue: null,
      customerPage: 1,
      customerLimit: 10,
      supplierPage: 1,
      supplierLimit: 10,
      limitData: this.limit,
      pagination: null,
      accessRights: [],
      selectFields: false,
      selectedCustomer: null,
      selectedSupplier: null,
      selectedCustomerGroup: null,
      selectedSupplierGroup: null,
      customerGroupValue: [],
      supplierGroupValue: [],
      selectedPaymentType: [],
      paymentGroupValue: [],
      search: "",
      listSkeleton: false,
      invoiceStartDateMenu: false,
      invoiceEndDateMenu: false,
      paymentStartDateMenu: false,
      paymentEndDateMenu: false,
      invoice_start_date: "2024-01-01",
      invoice_end_date: "2024-12-31",
      payment_start_date: "2024-01-01",
      payment_end_date: "2024-12-31",
      customerDialog: false,
      supplierDialog: false,
      defaultRadioValue: "detailed",
      valid: false,
      custom: { id: 111111111111111, description: this.$t("invoice.empty") },
      numberOfRows: "",
      invoiceData: [],
      isProgressCircular: false,
      typeHebrew: [
        "receipt_payment",
        "receipt_tax_invoice_payment",
        "supplier_receipt_payment",
      ],
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      row: "detailed",
      withoutCustomer: false,
      withoutSupplier: false,
    };
  },
  computed: {
    displayedHeaders() {
      switch (this.row) {
        case "detailed":
          return this.headers;
        case "daily":
          return this.dailyHeaders;
        case "monthly":
          return this.monthlyHeaders;
        case "focused":
          return this.focusedHeaders;
        default:
          return this.headers;
      }
    },
    formattedFromDate: {
      get() {
        return this.profileById.date_format
          ? formatDateDDMMYYYY(this.invoice_start_date)
          : parseDateYYYYMMDD(this.invoice_start_date);
      },
      set(val) {
        if (val.length == 10) {
          this.invoice_start_date = this.profileById.date_format
            ? this.parseDateYYYYMMDD(val)
            : formatDateDDMMYYYY(val);
        }
      },
    },
    formattedToDate: {
      get() {
        return this.profileById.date_format
          ? formatDateDDMMYYYY(this.invoice_end_date)
          : parseDateYYYYMMDD(this.invoice_end_date);
      },
      set(val) {
        if (val.length == 10) {
          this.invoice_end_date = this.profileById.date_format
            ? this.parseDateYYYYMMDD(val)
            : formatDateDDMMYYYY(val);
        }
      },
    },
    formattedPaymentFromDate: {
      get() {
        return this.profileById.date_format
          ? formatDateDDMMYYYY(this.payment_start_date)
          : parseDateYYYYMMDD(this.payment_start_date);
      },
      set(val) {
        if (val.length == 10) {
          this.payment_start_date = this.profileById.date_format
            ? this.parseDateYYYYMMDD(val)
            : formatDateDDMMYYYY(val);
        }
      },
    },
    formattedPaymentToDate: {
      get() {
        return this.profileById.date_format
          ? formatDateDDMMYYYY(this.payment_end_date)
          : parseDateYYYYMMDD(this.payment_end_date);
      },
      set(val) {
        if (val.length == 10) {
          this.payment_end_date = this.profileById.date_format
            ? this.parseDateYYYYMMDD(val)
            : formatDateDDMMYYYY(val);
        }
      },
    },
    params() {
      if (this.pagination) {
        return {
          ...this.pagination,
          customer_group_id: this.selectedCustomerGroup?.id,
          supplier_group_id: this.selectedSupplierGroup?.id,
          query: this.search,
          customer_id: this.selectedCustomer?.id,
          supplier_id: this.selectedSupplier?.id,
          invoice_start_date: this.invoice_start_date,
          invoice_end_date: this.invoice_end_date,
          payment_start_date: this.payment_start_date,
          payment_end_date: this.payment_end_date,
        };
      } else {
        return {};
      }
    },
    payload() {
      return {
        from_invoice_date: this.invoice_start_date,
        to_invoice_date: this.invoice_end_date,
        start_payment_date: this.payment_start_date,
        end_payment_date: this.payment_end_date,
        customer_group_id: this.selectedCustomerGroup?.description,
        supplier_group_id: this.selectedSupplierGroup?.description,
        customer_id: this.selectedCustomer?.name_1,
        supplier_id: this.selectedSupplier?.name_1,
      };
    },
    ...mapGetters({
      cashFlowReportList: "debitReport/cashFlowReportList",
      customerList: "customer/customerList",
      supplierList: "supplier/supplierList",
      locale: "locale",
      accessRight: "accessRight",
      profileById: "profile/profileById",
      rowIndex: "debitReport/rowIndex",
    }),
    dailyHeaders() {
      return [
        {
          text: this.$t("cashFlow.daily.date"),
          align: "start",
          value: "date",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t("cashFlow.daily.day"),
          value: "day",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t("cashFlow.daily.credit"),
          value: "totalcredit",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t("cashFlow.daily.debit"),
          align: "start",
          value: "totaldebit",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t("cashFlow.daily.dailyBalance"),
          value: "total",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t("cashFlow.daily.balance"),
          value: "balance",
          sortable: false,
          width: "120px",
        },
      ];
    },
    monthlyHeaders() {
      return [
        {
          text: this.$t("cashFlow.monthly.month"),
          align: "start",
          value: "month",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t("cashFlow.monthly.incoming"),
          value: "totalcredit",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t("cashFlow.monthly.outgoing"),
          value: "totaldebit",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t("cashFlow.monthly.totalMonthly"),
          align: "start",
          value: "total",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t("cashFlow.monthly.balance"),
          value: "balance",
          sortable: false,
          width: "120px",
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$t("cashFlow.detailed.date"),
          align: "start",
          value: "payment_date",
          width: "90px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.detailed.type"),
          value: "payment_type",
          width: "140px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.detailed.paymentType"),
          value: "payment_type_desc",
          width: "140px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.detailed.no"),
          value: "cheque_no",
          width: "112px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.detailed.bank"),
          value: "bank",
          width: "112px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.detailed.accountNo"),
          value: "account_no",
          width: "112px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.detailed.name"),
          value: "customer_name",
          width: "138px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.detailed.reference"),
          value: "document_number",
          width: "95px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.detailed.credit"),
          value: "credit",
          width: "156px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.detailed.debit"),
          value: "debit",
          width: "156px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.detailed.balance"),
          value: "cumulativeTotal",
          width: "156px",
          sortable: false,
        },
      ];
    },
    focusedHeaders() {
      return [
        {
          text: this.$t("cashFlow.focused.incoming"),
          value: "totalcredit",
          width: "156px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.focused.outgoing"),
          value: "totaldebit",
          width: "156px",
          sortable: false,
        },
        {
          text: this.$t("cashFlow.focused.balance"),
          value: "balance",
          width: "156px",
          sortable: false,
        },
      ];
    },
    empty() {
      return this.$t("invoice.empty");
    },
  },
  watch: {
    empty() {
      this.custom.description = this.empty;
    },
    customerLimit() {
      this.$store.dispatch("customer/GetCustomer", {
        page: 1,
        limit: this.customerLimit,
        order_by: "name_1|asc",
      });
    },
    supplierLimit() {
      this.$store.dispatch("supplier/GetSupplier", {
        page: 1,
        limit: this.supplierLimit,
        order_by: "name_1|asc",
      });
    },
    pagination: {
      handler(newVal) {
        if (this.pagination) {
          this.limit = newVal.itemsPerPage ? newVal.itemsPerPage : 10;
        }
      },
      deep: true,
    },
    cashFlowReportList: {
      handler(newVal) {
        this.invoiceData = [];
        if (newVal.data && newVal.data.length > 0) {
          if (this.row === "detailed") {
            newVal?.data?.forEach((item) => {
              item?.invoiceData?.forEach((dd) => {
                this.invoiceData.push(dd);
              });
              this.invoiceData.push({
                id: item.customer_name,
                credit: item.totalCredit,
                debit: item.totalDebit,
                // balance: item.cumulativeTotal,
              });
            });
          }
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.listSkeleton = true;
    this.row = this.defaultRadioValue;
    // set query in url
    this.$store.commit(
      "debitReport/SET_CASHFLOW_REPORT_QUERY",
      this.$route.query
    );
    // get customer list
    this.$store.dispatch("customer/GetCustomer", {
      page: this.customerPage,
      limit: this.customerLimit,
      order_by: "name_1|asc",
    });
    // get supplier list
    this.$store.dispatch("supplier/GetSupplier", {
      page: this.supplierPage,
      limit: this.supplierLimit,
      order_by: "name_1|asc",
    });
    let tableNameValue = await getAuxiliaryZtableValueByName(8);
    if (Object.keys(tableNameValue).length > 0) {
      this.paymentGroupValue = tableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
      this.paymentGroupValue = [this.custom, ...this.paymentGroupValue];
    }

    // get customer group
    getAuxiliaryZtableValueByName(2).then((tableCustomerValue) => {
      if (Object.keys(tableCustomerValue).length > 0) {
        this.customerGroupValue = tableCustomerValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.customerGroupValue = [this.custom, ...this.customerGroupValue];
      }
    });
    // get supplier group
    getAuxiliaryZtableValueByName(4).then((tableRegionNameValue) => {
      if (Object.keys(tableRegionNameValue).length > 0) {
        this.supplierGroupValue = tableRegionNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.supplierGroupValue = [this.custom, ...this.supplierGroupValue];
      }
    });
    this.listSkeleton = false;
  },
  destroyed() {
    this.$store.commit("debitReport/SET_CASHFLOW_REPORT", []);
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    newPagination(val) {
      this.pagination = val;
      this.numberOfRows = val.itemsLength;
    },
    async onCustomerChange(customer) {
      if (
        customer &&
        typeof customer === "object" &&
        Object.keys(customer).length > 0
      ) {
        this.selectedCustomer = { id: customer.id, name_1: customer?.name_1 };
        this.buildUrl();
      } else {
        this.selectedCustomer = null;
      }
    },
    async onSupplierChange(supplier) {
      if (
        supplier &&
        typeof supplier === "object" &&
        Object.keys(supplier).length > 0
      ) {
        this.selectedSupplier = { id: supplier.id, name_1: supplier?.name_1 };
        this.buildUrl();
      } else {
        this.selectedSupplier = null;
      }
    },
    doubleClick(res) {
      if (res.item.payment_type === "receipt_tax_invoice_payment") {
        const path =
          "/receiptTaxInvoice/receiptTaxInvoiceAction/" + res.item.document_id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else if (res.item.payment_type === "receipt_payment") {
        const path = "/receipt/editReceipt/" + res.item.document_id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      } else {
        const path =
          "/supplierPayment/editSupplierPayment/" + res.item.document_id;
        this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? window.open(path, "_blank")
          : "";
      }
      this.$store.commit("debitReport/SET_ROWINDEX", res.item.document_id);
    },
    resetFilters() {
      this.sortByValue = null;
      this.sortDescValue = null;
      this.invoice_start_date = "1990-01-01";
      this.invoice_end_date = "2099-12-31";
      this.payment_start_date = "1990-01-01";
      this.payment_end_date = "2099-12-31";
      this.search = "";
      this.selectedPaymentType = [];
      this.selectedCustomer = null;
      this.withoutCustomer = false;
      this.withoutSupplier = false;
      this.selectedSupplier = null;
      this.selectedCustomerGroup = null;
      this.selectedSupplierGroup = null;
      this.row = this.defaultRadioValue;
      this.$store.commit("debitReport/SET_CASHFLOW_REPORT_QUERY", {});
      this.buildUrl();
    },
    reset() {
      this.sortByValue = null;
      this.withoutCustomer = false;
      this.withoutSupplier = false;
      this.sortDescValue = null;
      this.search = "";
      this.selectedPaymentType = [];
      this.$store.commit("debitReport/SET_CASHFLOW_REPORT", []);
      this.row = this.defaultRadioValue;
      this.buildUrl();
    },
    numberWithCommas(x) {
      return x
        ? parseFloat(x)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : (0).toFixed(2);
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.customerLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async infiniteScrollSupplier([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.supplierLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async radioChange() {
      await this.$store.commit("debitReport/SET_CASHFLOW_REPORT", []);
      this.buildUrl();
    },
    dynamicColor(item) {
      if (this.row === "detailed") {
        if (!item.debit && item.payment_type) {
          return "item-credit";
        } else {
          return "";
        }
      }
    },
    downloadExcelFile() {
      let self = this;
      if (this.row === "detailed") {
        let res = [];
        self.invoiceData?.map((o) =>
          res.push(
            Object.fromEntries(self.headers.map((k) => [k.text, o[k.value]]))
          )
        );
        res.forEach((el)=> {
          for (let x in el) {
            if (!el[x]) {
              el[x] = '';
            }}
        })
        exportFromJSON({
          data: res,
          fileName: "detailedCashFlowReport",
          exportType: exportFromJSON.types.xls,
        });
      } else if (this.row === "daily") {
        let res = [];
        self.cashFlowReportList?.data?.forEach((el) => {
          res.push(
            Object.fromEntries(
              self.dailyHeaders.map((k) => [k.text, el[k.value]])
            )
          );
        });
        exportFromJSON({
          data: res,
          fileName: "dailyCashFlowReport",
          exportType: exportFromJSON.types.xls,
        });
      } else if (this.row === "monthly") {
        let res = [];
        self.cashFlowReportList?.data?.forEach((el) => {
          res.push(
            Object.fromEntries(
              self.monthlyHeaders.map((k) => [k.text, el[k.value]])
            )
          );
        });
        exportFromJSON({
          data: res,
          fileName: "monthlyCashFlowReport",
          exportType: exportFromJSON.types.xls,
        });
      } else if (this.row === "focused") {
        let res = [];
        self.cashFlowReportList?.data?.forEach((el) => {
          res.push(
            Object.fromEntries(
              self.focusedHeaders.map((k) => [k.text, el[k.value]])
            )
          );
        });
        exportFromJSON({
          data: res,
          fileName: "focusedCashFlowReport",
          exportType: exportFromJSON.types.xls,
        });
      }
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === "string" || typeof val === "object") {
        // this.page = 1;
        // obj.page = 1;
        // this.params.page = 1;
      }
      if (this.row === "daily") {
        obj.dailyreport = true;
      } else if (this.row === "monthly") {
        obj.monthly_report = true;
      } else if (this.row === "focused") {
        obj.all_records = true;
      }
      obj.custId = this.selectedCustomer?.id;
      obj.suppId = this.selectedSupplier?.id;
      obj.invoiceStartDate = this.invoice_start_date;
      obj.invoiceEndDate = this.invoice_end_date;
      obj.paymentStartDate = this.payment_start_date;
      obj.paymentEndDate = this.payment_end_date;
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj.customerGroupId = this.selectedCustomerGroup?.id;
      obj.supplierGroupId = this.selectedSupplierGroup?.id;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === "custId" && val === null) return acc;
        else if (key === "suppId" && val === null) return acc;
        else if (key === "customerGroupId" && val === null) return acc;
        else if (key === "invoiceStartDate" && val === null) return acc;
        else if (key === "invoiceEndDate" && val === null) return acc;
        else if (key === "paymentStartDate" && val === null) return acc;
        else if (key === "paymentEndDate" && val === null) return acc;
        else if (key === "order_by" && val === null) return acc;
        else if (key === "sortDec" && val === null) return acc;
        else if (key === "dailyreport" && val === null) return acc;
        else if (key === "monthly_report" && val === null) return acc;
        else if (key === "all_records" && val === null) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$store.commit("debitReport/SET_CASHFLOW_REPORT_QUERY", obj);
    },
    async paginate() {
      const {
        customer_group_id,
        supplier_group_id,
        customer_id,
        supplier_id,
        invoice_start_date,
        invoice_end_date,
        payment_start_date,
        payment_end_date,
      } = this.params;
      const selectedIds = this.selectedPaymentType
        .map((item) => item.id)
        .join(",");
      const payloadData = {
        doc_start_date: invoice_start_date,
        doc_end_date: invoice_end_date,
        start_date: payment_start_date,
        end_date: payment_end_date,
        customer_id: customer_id ? customer_id : undefined,
        supplier_id: supplier_id ? supplier_id : undefined,
        customer_group: customer_group_id ? customer_group_id : undefined,
        supplier_group: supplier_group_id ? supplier_group_id : undefined,
        payment_terms_id: selectedIds ? selectedIds : undefined,
        returnonly: this.withoutCustomer
          ? "onlysupplier"
          : this.withoutSupplier
          ? "onlycustomer"
          : undefined,
      };
      if (this.row === "daily") {
        payloadData.dailyreport = true;
      } else if (this.row === "monthly") {
        payloadData.monthly_report = true;
      } else if (this.row === "focused") {
        payloadData.all_records = true;
      }

      if (customer_group_id === 111111111111111) {
        payloadData.customer_group = "NULL";
      }

      if (supplier_group_id === 111111111111111) {
        payloadData.supplier_group = "NULL";
      }

      this.isProgressCircular = true;
      await this.$store
        .dispatch("debitReport/GetCashFlowReport", payloadData)
        .then(() => {
          this.isProgressCircular = false;
        });
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
::v-deep .item-credit {
  background-color: #cbe4a6 !important;
}
</style>
